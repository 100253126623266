/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormSelect from 'components/FormSelect'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import FormInputDate from 'components/FormInputDate'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { useStyles } from '../styles'

const transactionTypeOptions = {
  BALANCE: 'Balance',
  DEPOSIT: 'Deposit',
  COMMISSION: 'Commission',
  EXTRA_PAYMENT: 'Extra payment',
  REFUND: 'Refund',
  SERVICES: 'Services',
  INSTANT_PAYMENT: 'Instant Payment',
}

// eslint-disable-next-line react/display-name
export const TransactionLines = React.memo(({
  transactionLines,
  onDispatchValue,
  // disabled multiple transactions until import file support it
  // https://zube.io/applied-blockchain/travel-ledger/c/1044
  onAddTransactionLine,
  onRemoveTransactionLine
}) => {
  const classes = useStyles()
  const textFieldProps = { fullWidth: true, className: classes.formInput }

  const hasShowRemoveBtn = transactionLines.length > 1

  return (
    <Paper className={classes.topTransactionPaper} elevation={0}>
      {
        transactionLines.map((obj, k) => (
          <form key={k} noValidate autoComplete="off">
            <Grid direction="row" container item sm={12}>
              <Grid className={classes.fieldsMargin} item sm={4}>
                <FormSelect
                  {...textFieldProps}
                  name={`type_${obj.id}`}
                  label="Type"
                  onChange={onDispatchValue}
                  value={obj.type}
                  keyTitle={transactionTypeOptions}
                />
              </Grid>
              <Grid className={classes.fieldsMargin} item sm={4}>
                <FormInputDate
                  {...textFieldProps}
                  name={`dueDate_${obj.id}`}
                  label="Due data"
                  value={obj.dueDate}
                  onChange={onDispatchValue}
                  required={k === 0}
                />
              </Grid>
              <Grid className={classes.formInput} item sm={3}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  name={`amount_${obj.id}`}
                  label="Amount"
                  value={obj.amount}
                  onChange={onDispatchValue}
                  required={k === 0}
                />
              </Grid>
              <Grid container item sm={1} justify="center" alignItems="center">
                {hasShowRemoveBtn && (
                  <Tooltip title="Remove transaction line">
                    <IconButton size="small" onClick={() => onRemoveTransactionLine(obj.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </form>
        ))
      }
    </Paper>
  )
})

TransactionLines.propTypes = {
  transactionLines: PropTypes.array,
  onDispatchValue: PropTypes.func,
  onAddTransactionLine: PropTypes.func,
  onRemoveTransactionLine: PropTypes.func
}
