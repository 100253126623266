/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Typography } from '@material-ui/core'
import Button from 'components/Button'
import { useStyles } from './styles'
import { Controller, useFormContext } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ConfirmDialog from 'components/ConfirmDialog'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { post } from '../../../../../utils/api'
import { useMutation } from 'react-query'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { forEach, cloneDeep } from 'lodash'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import { EWALLET_PROVIDER, EWALLET_REGION, NIUM_ONBOARD_STATUS, NIUM_STAKEHOLDER_TYPE } from 'constants.js'
import FormHelperText from '@material-ui/core/FormHelperText'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { FormStructureProviderMap } from './form-schemas/providerForms'
import { TYPE } from './form-schemas/formTypes'
// sub-components
import DebouncedTextField from './sub-components/DebounceTextField'
import CountryComponent from './sub-components/CountryComponent'
import SelectComponent from './sub-components/SelectComponent'
import FileComponent from './sub-components/FileComponent'
import { CustomAccordion, CustomAccordionActions, CustomAccordionContent, CustomAccordionTitle } from './sub-components/CustomAccordions'
import { businessTypePositionMap } from './form-schemas/nium/values'
import { useIntl } from 'react-intl'

const KYBForm = ({ goToNextStep, companyId, provider, signup, saveData }) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [isPreFill, setIsPreFill] = useState(true)
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const [savedSections, setSavedSections] = useState([])
  const {
    formStructure,
    documentDetailsCorporate,
    documentDetailsPerson,
    stakeholdersFields,
    documentDetailsCorporateStakeholders,
    allDocumentTypes,
  } = FormStructureProviderMap[provider] || {}
  const [form, setForm] = useState(formStructure)
  const { formatMessage } = useIntl()

  const {
    providerStatus,
    data: { remarks, customerHashId } = {}
  } = signup || {}
  const mustResubmit = providerStatus === NIUM_ONBOARD_STATUS.ERROR && !!customerHashId
  const submissionErrors = (remarks && remarks.split('|')) || []

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: {
      errors,
      submitCount
    },
  } = useFormContext()
  // log validation errors in console
  errors && Object.keys(errors).length && console.error(errors)
  // automatically set region by provider
  useEffect(() => {
    if(provider) {
      const region = provider === EWALLET_PROVIDER.NIUM_EU ? EWALLET_REGION.EU : EWALLET_REGION.UK
      setValue('region', region)
    }
  }, [provider, setValue])

  // manage data driven business type - position (NIUM EU) - ON CHANGE
  const [euBusinessType, setEuBusinessType] = useState()
  const [euPositionUpdated, setEuPositionUpdated] = useState(false)
  watch(values => {
    if(provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }
    const {
      businessDetails: {
        businessType,
      } = {}
    } = values || {}
    if (businessType && businessType !== euBusinessType) {
      setEuBusinessType(businessType)
      setEuPositionUpdated(false)
    }
  })
  useEffect(() => {
    if (provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }
    if(euBusinessType && !euPositionUpdated) {
      const filteredOptions = businessTypePositionMap[euBusinessType]
      const clonedForm = cloneDeep(form)
      clonedForm.applicantDetails.fields['professionalDetails.0'].fields.position.options = { ...filteredOptions }
      clonedForm.stakeholders.fields.forEach(shFields => {
        shFields.businessPartner.fields.businessEntityType.options = { ...filteredOptions }
        shFields.stakeholderDetails.fields['professionalDetails.0'].fields.position.options = { ...filteredOptions }
      })
      setForm(clonedForm)
      setEuPositionUpdated(true)
    }
  }, [provider, euBusinessType, form, euPositionUpdated])

  // manage same address onchange
  const [addressSubActive, setAddressSubActive] = useState(null)
  const copyRegisteredAddress = useCallback((values) => {
    const {
      businessDetails: {
        addresses: {
          registeredAddress
        }
      }
    } = values
    for (const key in registeredAddress) {
      const extendedKey = `businessDetails.addresses.businessAddress.${key}`
      if (getValues(extendedKey) !== registeredAddress[key]) {
        setValue(extendedKey, registeredAddress[key])
      }
    }
  }, [setValue, getValues])
  
  const clearBusinessAddress = useCallback(() => {
    for (const key in formStructure.businessDetails.fields.addresses.fields.businessAddress.fields) {
      setValue(`businessDetails.addresses.businessAddress.${key}`, '')
    }
  }, [formStructure, setValue])

  useEffect(() => {
    let unsubscribe = () => {}
    if(addressSubActive === null) {
      // if never changed, do nothing
      return
    }
    if (addressSubActive) {
      // immediate copy
      copyRegisteredAddress(getValues())
      // onchange
      unsubscribe = watch(values => {
        copyRegisteredAddress(values)
      }).unsubscribe
    } else {
      clearBusinessAddress()
    }
    return () => {
      unsubscribe() 
    }
  }, [addressSubActive, copyRegisteredAddress, watch, getValues, clearBusinessAddress])

  const manageSameAddress = (e) => {
    setAddressSubActive(e.target.value === 'YES')
  }

  const kybCorporateOnboardEWallet = (onboardInfo) => {
    return post(
      `/e-wallet/kyb-corporate-onboard`, 
      { ...onboardInfo, companyId, provider, ...(mustResubmit ? { customerHashId } : {}) }
    )
  }

  const { mutate: signupEWalletMutation, isLoading } = useMutation(kybCorporateOnboardEWallet, {
    onSuccess: (response) => {
      toast.success('KYB Wallet application sent successfully.')
      setGlobalSpinner(false)
      goToNextStep(response)
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  useEffect(() => {
    if (isLoading) {
      setGlobalSpinner(true)
    } else {
      setGlobalSpinner(false)
    }
  }, [isLoading, setGlobalSpinner])

  const onSubmit = handleSubmit((formData) => {
    // save on submit
    saveData()
    // build request object
    const refinedFormData = cloneDeep(formData)
    signupEWalletMutation(refinedFormData)
  })

  const expandCascade = (element, nameId) => {
    // reached top element, exit
    if(element.id === nameId) {
      return
    }
    const parent = element.parentNode
    if(parent.classList && [...parent.classList].includes('MuiCollapse-hidden')) {
      // collapsed section: find expand button and click it
      const sibling = [...parent.parentNode.childNodes].find(s => [...s.classList].includes('MuiAccordionSummary-root'))
      const expandIcon = [...sibling.childNodes].find(cn => [...cn.classList].includes('MuiAccordionSummary-expandIcon'))
      expandIcon && expandIcon.click()
    }
    return expandCascade(parent)
  }

  const afterSave = (validationResult, nameId) => {
    if(validationResult) {
      // in case of successful validation
      // find expand button by section id and click it to close the section
      document.querySelector(`#section-${nameId} .MuiAccordionSummary-expandIcon`).click()
    } else {
      // in case of failed validation - find the first error
      const firstError = document.querySelector(`#section-${nameId} .Mui-error`)
      // first expand all nodes that are hidden
      if(firstError) {
        expandCascade(firstError, nameId)
        // then scroll to the first error encountered in the section
        firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  const removeGroupItem = (origin, index) => {
    const numArray = origin.match(/\d+/g)
    const numArr = numArray && numArray[0] ? Number(numArray[0]) : undefined
    const toUpdate = watch(origin)
    if(!toUpdate) {
      return
    }
    if (origin === 'stakeholders') {
      form.stakeholders.fields.splice(index, 1)
    } else if (origin === 'businessDetails.documentDetails') {
      form.businessDetails.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `applicantDetails.documentDetails`) {
      form.applicantDetails.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `stakeholders.${numArr}.stakeholderDetails.documentDetails`) {
      form.stakeholders.fields[numArr].stakeholderDetails.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `stakeholders.${numArr}.businessPartner.documentDetails`) {
      form.stakeholders.fields[numArr].businessPartner.fields.documentDetails.fields.splice(index, 1)
    }
    toUpdate.splice(index, 1)
    setValue(origin, toUpdate)
    setForm({ ...form })
  }


  const renderFormItem = (formItem, origin) => {
    const nameId = origin
    const isRootLevel = origin.split('.').length === 1

    if (formItem.renderIf) {
      let shouldRender = true
      const [index] = origin.match(/\d+/g)
      forEach(formItem.renderIf, (value, key) => {
        const parsedKey = key.replace('index', index)
        const watchValue = watch(parsedKey, false)
        const itChecks = watchValue === value
        if (!itChecks) {
          shouldRender = false
        }
      })
      if (!shouldRender) {
        return <></>
      }
    }

    const renderGroupHeaderContent = (item, index) => {
      let content = `Document ${index + 1} *`
      if (item.documentType) {
        const documentType = watch(`${origin}.${index}.documentType`, '')
        if(documentType) {
          content += ' - ' + allDocumentTypes[documentType]
        }
        // removed since now it's multi selection
        // const documentFileName = watch(`${origin}.${index}.document.0.fileName`, '')  
        // if (documentFileName) {
        //   content += ' - ' + documentFileName
        // }
      }
      if (item.stakeholderType) {
        content = `Stakeholder ${index + 1}`
        const businessName = watch(`${origin}.${index}.businessPartner.businessName`, '')
        if (businessName) {
          content += ` - ${businessName}`
        } else {
          const firstName = watch(`${origin}.${index}.stakeholderDetails.firstName`, '')
          if (firstName) {
            content += ` - ${firstName}`
          }
          const lastName = watch(`${origin}.${index}.stakeholderDetails.lastName`, '')  
          if (lastName) {
            if (!firstName) {
              content += ' - '
            }
            content += ' ' + lastName
          }
        }
      }
      return content || index + 1
    }

    if (formItem.type === TYPE.GROUP) {
      const showBadge = isRootLevel && (submitCount > 0 || savedSections.includes(nameId))
      let sectionContent = ''
      if (formItem.multiple) {
        const getSpecificLabel = (code, plural = false) => code.includes('documentDetails') ? `Document${plural ? 's' : ''}` : 
          code.includes('stakeholders') ? `Stakeholder${plural ? 's' : ''}` : ''
        sectionContent = <>
          <CustomAccordionContent>
            <div className={classes.fullWidth}>
              {formItem.info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: formItem.info }} />}
              {formItem.fields.map((item, index) => (
                <CustomAccordion key={index}>
                  <CustomAccordionTitle expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      {renderGroupHeaderContent(item, index)}
                    </Typography>
                  </CustomAccordionTitle>
                  <CustomAccordionContent>
                    <div style={{ width: '100%' }}>
                      {Object.entries(item).map(([k, v]) => renderFormItem(v, `${origin}.${index}.${k}`))}
                    </div>
                  </CustomAccordionContent>
                  {formItem.fields && formItem.fields.length > 1 && <CustomAccordionActions>
                    <Button size="small" color="primary" onClick={() => removeGroupItem(origin, index)}>
                    {formatMessage({ id: 'tlpay.remove' })} {getSpecificLabel(origin)}
                    </Button>
                  </CustomAccordionActions>}
                </CustomAccordion>
              ))}
            </div>
          </CustomAccordionContent>
          <CustomAccordionActions>
            <Button size="small" color="primary" onClick={() => addMore(origin)}>
              {formatMessage({ id: 'tlpay.add-more' })} {getSpecificLabel(origin, true)}
            </Button>
          </CustomAccordionActions>
        </>
      } else {
        sectionContent = <>
            <CustomAccordionContent>
            <div className={classes.fullWidth}>
              {formItem.info && <Typography color="textSecondary">{formItem.info}</Typography>}
              {Object.entries(formItem.fields).map(([k, v]) => renderFormItem(v, `${origin}.${k}`))}
            </div>
          </CustomAccordionContent>
        </>
      }
      
      return (
        <CustomAccordion
        className={[
          formItem.visibleIfFalse && watch(formItem.visibleIfFalse, 'YES') !== 'NO' && classes.hidden,
          isRootLevel && classes.rootSection
        ]}
        id={`section-${nameId}`}
        {...(formItem.noExpand && { expanded: true })}
      >
        <CustomAccordionTitle 
            {...(!formItem.noExpand && { expandIcon: <ExpandMoreIcon /> })}>
          <Typography
            {...!isRootLevel && { className: classes.heading }}
            {...isRootLevel && { variant: 'h4' }}
          >
            {showBadge && (Object.hasOwn(errors, nameId) ? <ErrorIcon color="error" className={classes.icon} fontSize="small" /> : <CheckCircleIcon className={[classes.successIcon, classes.icon]} fontSize="small" />)}
            {formItem.title}
          </Typography>
        </CustomAccordionTitle>
        {sectionContent}
        <div className={classes.buttonContainerSection}>
            {isRootLevel && <Button
              disabled={isLoading}
              variant="extended"
              type="submit"
              size="large"
              aria-label="Save"
              color="primary"
              onClick={() => { 
                saveData(nameId).then(validationResult => {
                  afterSave(validationResult, nameId)
                })
                if(!savedSections.includes(nameId)) {
                  setSavedSections(prevState => [...prevState, nameId])
                }
              }}
              className={classes.buttonSpacing}
            >
              {formatMessage({ id: 'tlpay.save-data' })}
            </Button>}
          </div>
      </CustomAccordion>
      )
    }
    if (formItem.type === TYPE.STRING) {
      return (
        <Controller
          control={control}
          name={nameId}
          render={(props) => (
            <DebouncedTextField
              controllerProps={props}
              label={formItem.label}
              required={formItem.required}
              info={formItem.info}
              decimalDigits={formItem.decimalDigits}
              customErrorMessageTypes={formItem.customErrorMessageTypes}
            />
          )}
        />
      )
    }
    if (formItem.type === TYPE.SELECT) {
      return <SelectComponent nameId={nameId} formItem={formItem} />
    }
    if (formItem.type === TYPE.COUNTRY) {
      return <CountryComponent nameId={nameId} formItem={formItem} />
    }
    if (formItem.type === TYPE.DATE) {
      const getError = (error) => {
        const { type } = error
        if(type === 'date.max') {
          return formatMessage({ id: 'tlpay.cannot-be-set-in-the-future' })
        }
        if(type === 'date.min') {
          return formatMessage({ id: 'tlpay.cannot-be-set-in-the-past' })
        }
        if(type === 'date.format') {
          return formatMessage({ id: 'tlpay.invalid-date' })
        }
        
        return formatMessage({ id: 'tlpay.is-required' })
      }
      const today = new Date()
      const yesterday = new Date().setDate(today.getDate() - 1)
      return (
        <Controller
          render={({ field: { onChange, value = null }, fieldState }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                clearable
                label={formItem.label + (formItem.required ? ' *' : '')}
                inputVariant="outlined"
                format="yyyy-MM-dd"
                onChange={(newDate) => {
                  // in case of "past" date, automatically set the date to yesterday if today is selected manually
                  if(formItem.past && newDate && newDate.getFullYear() === today.getFullYear() &&
                    newDate.getMonth() === today.getMonth() && newDate.getDate() === today.getDate()) {
                    newDate.setDate(newDate.getDate() - 1)
                  }
                  return onChange(moment(newDate).format('YYYY-MM-DD'))
                }}
                value={value}
                emptyLabel="YYYY-MM-DD"
                size='small'
                error={!!fieldState.error}
                helperText={fieldState.error && getError(fieldState.error)}
                {...(formItem.future ? { minDate: today } : {} )}
                {...(formItem.past ? { maxDate: yesterday } : {} )}
              />
              {formItem.info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: formItem.info }} />}
            </MuiPickersUtilsProvider>
          )}
          control={control}
          name={nameId}
          error={false}
        />
      )
    }
    if (formItem.type === TYPE.FILE) {
      return <FileComponent nameId={nameId} formItem={formItem} />
    }
    if (formItem.type === TYPE.RADIO) {
      return (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl
              className={[
                classes.field,
                formItem.visibleIfTrue && watch(formItem.visibleIfTrue, 'NO') !== 'YES' && classes.hidden,
              ]}
            >
              {formItem.label &&
                <FormLabel
                  className={classes.primaryLabel}
                  {...formItem.required && { required: true }}
                >
                  {formItem.label}
                </FormLabel>
              }
              {formItem.info &&
                <FormLabel
                  className={[classes.field, classes.primaryLabel]}
                >
                  {formItem.info}
                </FormLabel>
              }
              <RadioGroup
                row
                {...field}
                onChange={(e) => {
                  if (nameId.includes('stakeholderType')) {
                    if (e.target.value === NIUM_STAKEHOLDER_TYPE.CORPORATE) {
                      setValue(`${nameId.replace('stakeholderType','stakeholderDetails')}`, undefined)
                    }
                    if (e.target.value === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
                      setValue(`${nameId.replace('stakeholderType','businessPartner')}`, undefined)
                    }
                  }
                  if(nameId === 'businessDetails.addresses.isSameAddress') {
                    manageSameAddress(e)
                  }
                  field.onChange(e.target.value)
                }}
                name={nameId}
              >
                {Object.entries(formItem.options).map(([value, label]) => (
                  <FormControlLabel key={value} value={value} control={<Radio color="primary" />} label={label} />
                ))}
              </RadioGroup>
              {error && <FormHelperText error>{formatMessage({ id: 'tlpay.is-required' })}</FormHelperText>}
            </FormControl>
          )}
          control={control}
          name={nameId}
          error={false}
        />
      )
    }
  }

  const addMore = useCallback(
    (origin) => {
      const numArray = origin.match(/\d+/g)
      const numArr = numArray && numArray[0] ? Number(numArray[0]) : undefined
      if (origin === 'stakeholders') {
        const shFields = cloneDeep(stakeholdersFields)
        if(provider === EWALLET_PROVIDER.NIUM_EU) {
          // manage data driven business type - position (NIUM EU) - ADD SH
          const businessType = watch('businessDetails.businessType')
          if(businessType) {
            const filteredOptions = businessTypePositionMap[businessType]
            shFields.businessPartner.fields.businessEntityType.options = { ...filteredOptions }
            shFields.stakeholderDetails.fields['professionalDetails.0'].fields.position.options = { ...filteredOptions }
          }
        }
        form.stakeholders.fields.push(cloneDeep(shFields))
        setForm({ ...form })
      } else if (origin === 'businessDetails.documentDetails') {
        form.businessDetails.fields.documentDetails.fields.push(cloneDeep(documentDetailsCorporate))
        setForm({ ...form })
      } else if (origin === 'applicantDetails.documentDetails') {
        form.applicantDetails.fields.documentDetails.fields.push(cloneDeep(documentDetailsPerson))
        setForm({ ...form })
      } else if (origin === `stakeholders.${numArr}.stakeholderDetails.documentDetails`) {
        form.stakeholders.fields[numArr].stakeholderDetails.fields.documentDetails.fields.push(
          cloneDeep(documentDetailsPerson)
        )
        setForm({ ...form })
      } else if (origin === `stakeholders.${numArr}.businessPartner.documentDetails`) {
        form.stakeholders.fields[numArr].businessPartner.fields.documentDetails.fields.push(
          cloneDeep(documentDetailsCorporateStakeholders)
        )
        setForm({ ...form })
      }
    },
    [form],
  )

  // manage multiple elements pre-fill
  useEffect(() => {
    const { 
      stakeholders = [],
      businessDetails: {
        documentDetails: businessDocuments = []
      } = {},
      applicantDetails: {
        documentDetails: applicantDocuments = []
      } = {},
    } = getValues()

    if (isPreFill) {
      // add stakeholders
      const shFormCount = form.stakeholders.fields.length
      if(stakeholders &&
          stakeholders.length !== shFormCount &&
          stakeholders.length > 1) {
        for (let i = shFormCount; i < stakeholders.length; i++) {
          addMore('stakeholders')
        }
      }
      
      // add stakeholder documents
      for(let j = 0; j < stakeholders.length; j++) {
        const sh = stakeholders[j]
        if(sh.stakeholderType === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
          const {
            stakeholderDetails: {
              documentDetails
            } = {}
          } = sh
          const shIndividualDocCount = form.stakeholders.fields[j].stakeholderDetails.fields.documentDetails.fields.length
          if(documentDetails &&
              documentDetails.length !== shIndividualDocCount &&
              documentDetails.length > 1) {
            for (let i = shIndividualDocCount; i < documentDetails.length; i++) {
              addMore(`stakeholders.${j}.stakeholderDetails.documentDetails`)
            }
          }
        } else if(sh.stakeholderType === NIUM_STAKEHOLDER_TYPE.CORPORATE) {
          const {
            businessPartner: {
              documentDetails
            } = {}
          } = sh
          const shCorporateDocCount = form.stakeholders.fields[j].businessPartner.fields.documentDetails.fields.length
          if(documentDetails &&
              documentDetails.length !== shCorporateDocCount &&
              documentDetails.length > 1) {
            for (let i = shCorporateDocCount; i < documentDetails.length; i++) {
              addMore(`stakeholders.${j}.businessPartner.documentDetails`)
            }
          }
        }

      }
      
      // add applicant documents
      const applicantDocCount = form.applicantDetails.fields.documentDetails.fields.length
      if(applicantDocuments &&
          applicantDocuments.length !== applicantDocCount &&
          applicantDocuments.length > 1) {
        for (let i = applicantDocCount; i < applicantDocuments.length; i++) {
          addMore('applicantDetails.documentDetails')
        }
      }

      // add business documents
      const businessDocCount = form.businessDetails.fields.documentDetails.fields.length
      if(businessDocuments &&
          businessDocuments.length !== businessDocCount &&
          businessDocuments.length > 1) {
        for (let i = businessDocCount; i < businessDocuments.length; i++) {
          addMore('businessDetails.documentDetails')
        }
      }
      
      setIsPreFill(false)
    }
  }, [getValues, addMore, form, isPreFill])

  return (
    <Card variant="outlined" className={classes.formContainer}>
      {mustResubmit && <div style={{ padding: '1em', paddingBottom: 0 }}>
        <Typography>{formatMessage({ id: 'tlpay.there-have-been-errors-in-your-application-please-resubmit-after-correcting-the-below-errors' })}: </Typography>
        <ul>
          {submissionErrors.map((s, i) => <li style={{ marginBottom: '10px' }} key={i}> {s} </li>)}
        </ul>
      </div>}
      <CardContent>
        <Typography variant="h3" gutterBottom>
          {formatMessage({ id: 'tlpay.step-1-kyb-form' })}
        </Typography>
        {Object.entries(form).map(([k, v]) => (
          <div key={k}>{renderFormItem(v, k)}</div>
        ))}
        <div className={classes.buttonContainer}>
          <Button
            disabled={isLoading}
            variant="extended"
            type="submit"
            size="large"
            color="success"
            aria-label="add"
            onClick={() => setConfirmOpen(true)}
            className={classes.buttonSpacing}
          >
            {formatMessage({ id: 'tlpay.next' })}
          </Button>
        </div>
        
        <ConfirmDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={() => onSubmit()}
          title="Confirm and submit KYB application"
          noText="Cancel"
          yesText="Submit"
        >
          <Typography paragraph>
            {formatMessage({ id: 'tlpay.once-submitted-your-application-will-be-reviewed-if-any-additional-information-is-required-you-will-be-notified' })}
          </Typography>
          <Typography paragraph>
            {formatMessage({ id: 'tlpay.please-note-that-you-will-not-be-able-to-edit-or-change-your-information-during-the-approval-process-outside-of-any-requests-for-additional-information' })}
          </Typography>
        </ConfirmDialog>
      </CardContent>
    </Card>
  )
}

export default KYBForm
